import React, { memo } from 'react'
import { Card, CardHeader } from '@material-ui/core'
import Avatar from './avatar'
import Action from './action'
import useStyles from './styles'
import classnames from 'classnames'

const Row = memo(({ avatarIcon, actionIcon, children, classes = {}, avatarVariant, ...props }) => {
  const rowClasses = useStyles()
  return (
    <Card className={classnames(rowClasses.root, classes.root)}>
      <CardHeader
        avatar={<Avatar icon={avatarIcon} variant={avatarVariant} className={classes.avatar} />}
        action={<Action icon={actionIcon} className={classes.action} />}
        {...props}
      />
      {children}
    </Card>
  )
})

export default Row
